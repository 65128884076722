<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">
        Order Pembelian - {{ id > 0 ? 'Edit' : 'Tambah' }}
      </span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">
          / Pembelian / Order Pembelian / {{ id > 0 ? 'Edit' : 'Tambah' }}
        </span>
      </div>
    </div>
    <!-- <form @submit.prevent="onConfirmSave(!v$.$invalid)"> -->
    <div class="card bg-white">
      <div class="flex justify-content-end mb-3">
        <Button
          :loading="isLoadingSave"
          icon="pi pi-save"
          class="mr-2"
          :label="id > 0 ? 'Update' : 'Simpan'"
          @click="onConfirmSave(!v$.$invalid)"
        />
        <Button
          :disabled="old_status_value === 'done'"
          icon="pi pi-plus"
          class="p-button-outlined mr-2"
          label="Item"
          @click="onAddData"
        />
        <Button
          class="p-button-link"
          label="Kembali"
          @click="$router.push({ name: 'PurchaseOrder' })"
        />
      </div>
      <div class="grid">
        <div class="col-12 md:col-10">
          <div class="formgrid grid">
            <div class="field col-12 md:col-3">
              <label>Tgl Order</label>
              <Calendar
                v-model="form.pr_date"
                dateFormat="dd/mm/yy"
                class="w-full"
                :class="{ 'p-invalid': v$.form.pr_date.$invalid && submitted }"
                input-class="w-full"
                @date-select="setDateJthTempo"
              />
              <small
                v-if="
                  (v$.form.pr_date.$invalid && submitted) ||
                  v$.form.pr_date.$pending.$response
                "
                class="p-error"
                >{{ v$.form.pr_date.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-5">
              <label>No Order</label>
              <InputText
                v-model="form.pr_no"
                class="w-full"
                input-class="w-full"
                maxlength="50"
                disabled
              />
            </div>
            <div class="field col-12 md:col-4">
              <label>Supplier</label>
              <AutoComplete
                ref="supplier"
                v-model="selectedSupplier"
                class="w-full"
                input-class="w-full"
                placeholder="Search Supplier"
                :suggestions="listSupplier"
                :class="{
                  'p-invalid': v$.form.supp_id.$invalid && submitted,
                }"
                :autofocus="!this.id > 0"
                field="name"
                :disabled="old_status_value === 'done'"
                @complete="searchItemSuppliers"
                @item-select="onSelectSupplier"
                @keydown.enter="supplierInputEnter"
              >
              </AutoComplete>
              <small
                v-if="
                  (v$.form.supp_id.$invalid && submitted) ||
                  v$.form.supp_id.$pending.$response
                "
                class="p-error"
                >{{ v$.form.supp_id.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-3">
              <label>TGL JATUH TEMPO</label>
              <Calendar
                ref="due_at"
                v-model="form.due_at"
                dateFormat="dd/mm/yy"
                class="w-full"
                input-class="w-full"
              />
            </div>

            <div class="field col-12 md:col-5">
              <label>PEMBAYARAN</label>
              <SelectButton
                ref="payment"
                v-model="form.payment"
                :options="[
                  { name: 'Tunai', value: 'tunai' },
                  { name: 'Giro', value: 'giro' },
                  { name: 'Transfer', value: 'transfer' },
                ]"
                style="width: 500px"
                class="w-100"
                optionValue="value"
                optionLabel="name"
              />
              <small
                v-if="
                  (v$.form.payment.$invalid && submitted) ||
                  v$.form.payment.$pending.$response
                "
                class="p-error"
                >{{ v$.form.payment.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-4">
              <label>No Invoice</label>
              <InputText
                v-model="form.inv_no"
                class="w-full"
                input-class="w-full"
                maxlength="50"
              />
            </div>
            <div class="field col-12 md:col-3">
              <label>Tgl Terima</label>
              <Calendar
                v-model="form.rcv_at"
                dateFormat="dd/mm/yy"
                class="w-full"
                input-class="w-full"
              />
            </div>
            <div class="field col-12 md:col-2">
              <label>PPN</label>
              <div class="p-inputgroup flex-1">
                <span class="p-inputgroup-addon bg-white">
                  <Checkbox
                    v-model="isCheck"
                    @click="OnCheckClick"
                    @keydown.enter="OnCheckClick"
                    binary
                    :disabled="old_status_value === 'done'"
                  />
                  <!-- <label class="ml-2">PPN</label> -->
                </span>
                <InputNumber
                  v-model="form.tax"
                  disabled
                  locale="id-ID"
                  :min="0"
                  :max="100"
                />
                <span class="p-inputgroup-addon">%</span>
              </div>
            </div>
            <div class="field col-12 md:col-3">
              <label>TERMIN</label>
              <Dropdown
                v-model="form.term_id"
                class="w-full"
                input-class="w-full"
                :class="{
                  'p-invalid': v$.form.term_id.$invalid && submitted,
                }"
                :options="list_term"
                optionValue="id"
                optionLabel="desc"
                :showClear="true"
                placeholder="Pilih Termin"
                filter
                @change="setDateJthTempo"
              >
                <template #option="slotProps">
                  <b>{{ slotProps.option.code }}</b
                  ><br />
                  {{ slotProps.option.desc }}
                </template>
              </Dropdown>
              <small
                v-if="
                  (v$.form.term_id.$invalid && submitted) ||
                  v$.form.term_id.$pending.$response
                "
                class="p-error"
                >{{ v$.form.term_id.required.$message }}</small
              >
            </div>

            <div class="field col-12 md:col-2">
              <label>Status</label>
              <Dropdown
                v-model="form.status"
                :options="list_status"
                :disabled="!id || old_status_value === 'done'"
                optionValue="value"
                optionLabel="label"
                optionDisabled="disabled"
                class="w-full"
                input-class="w-full"
              />
            </div>
            <div class="field col-12 md:col-2">
              <label>whouse</label>
              <InputNumber
                v-model.number="form.whouse"
                class="w-full"
                input-class="w-full"
                :minFractionDigits="0"
                :maxFractionDigits="2"
                :min="0"
                locale="id-ID"
              />
            </div>
            <div class="field col-12 md:col-3">
              <label>DISC Reguler</label>
              <div class="p-inputgroup flex-1">
                <span v-if="disc_reg > 100" class="p-inputgroup-addon">
                  Rp
                </span>
                <InputNumber
                  ref="disc_reg"
                  v-model="disc_reg"
                  locale="id-ID"
                  class="w-full"
                  input-class="w-full"
                  :disabled="old_status_value === 'done'"
                  :min="0"
                  :minFractionDigits="0"
                  :maxFractionDigits="2"
                  @update:model-value="changeDiscReg"
                  @input="disc_reg = $event.value"
                  @focus="selectAllText('disc_reg')"
                />
                <span v-if="disc_reg <= 100" class="p-inputgroup-addon">
                  %
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <fx-table
          :items="form.details"
          :total="form.details.length"
          :loading="isLoading"
          :paginator="false"
          hasFooter
          showGridlines
        >
          <template #columns>
            <Column
              field="item_sku"
              header="SKU"
              style="min-width: 8rem; background-color: #f8f9fa"
            />
            <Column
              field="item_barcode_1"
              header="Barcode"
              style="min-width: 8rem; background-color: #f8f9fa"
            />
            <Column
              field="item_name"
              header="Deskripsi"
              style="min-width: 12rem; background-color: #f8f9fa"
            />

            <Column
              field="unit_b"
              header="Satuan"
              style="min-width: 6rem; background-color: #f8f9fa"
            >
              <template #body="slotProps">
                {{ slotProps.data.unit_b }}.{{ slotProps.data.unit_s }}
              </template>
            </Column>
            <Column
              field="ubox"
              header="ISI"
              style="
                min-width: 6rem;
                background-color: #f8f9fa;
                justify-content: flex-end;
              "
            />
            <Column
              field="qty_b"
              header="qty (b)"
              style="min-width: 6rem; justify-content: flex-end"
            />
            <Column
              field="qty_s"
              header="qty (s)"
              style="min-width: 6rem; justify-content: flex-end"
            />

            <Column
              field="qty"
              header="QTY (PCS)"
              style="
                min-width: 8rem;
                background-color: #f8f9fa;
                justify-content: flex-end;
              "
            >
            </Column>

            <Column
              field="cost"
              header="Harga"
              style="min-width: 10rem; justify-content: flex-end"
            >
              <template #body="slotProps">
                <span style="text-align: right">
                  {{ formatCurrency(slotProps.data.cost) }}
                </span>
              </template>
            </Column>
            <Column
              field="cost_per"
              header="PER"
              style="min-width: 5rem; justify-content: flex-start"
            >
            </Column>
            <Column
              field="disc_reg"
              header="DISC.REG"
              style="min-width: 8rem; justify-content: flex-end"
            >
              <template #body="slotProps">
                {{
                  slotProps.data.disc_reg > 100
                    ? formatCurrency(slotProps.data.disc_reg)
                    : `${slotProps.data.disc_reg || 0}%`
                }}
              </template>
            </Column>
            <Column
              field="disc_ext"
              header="DISC.EXT"
              style="min-width: 8rem; justify-content: flex-end"
            >
              <template #body="slotProps">
                {{
                  slotProps.data.disc_ext > 100
                    ? formatCurrency(slotProps.data.disc_ext || 0)
                    : `${slotProps.data.disc_ext || 0}%`
                }}
              </template>
            </Column>
            <!-- <Column
              field="tax"
              header="PPN"
              style="min-width: 3rem; justify-content: flex-end"
            >
              <template #body="slotProps">
                <span style="text-align: right">
                  {{ slotProps.data.tax }}%
                </span>
              </template>
            </Column> -->
            <Column
              field="bruto"
              header="Jumlah"
              style="
                min-width: 12rem;
                max-width: 12rem;
                background-color: #f8f9fa;
                justify-content: flex-end;
              "
            >
              <template #body="slotProps">
                {{ formatCurrency(slotProps.data.bruto) }}
              </template>
            </Column>
            <Column style="flex-grow: 1; justify-content: flex-end">
              <template #body="{ data }">
                <Button
                  type="button"
                  icon="pi pi-pencil"
                  class="
                    p-button-outlined p-button-secondary p-button-text
                    mr-2
                  "
                  :disabled="old_status_value === 'done'"
                  @click="onEditData(data)"
                />
                <Button
                  type="button"
                  icon="pi pi-trash"
                  class="p-button-outlined p-button-danger p-button-text"
                  :disabled="old_status_value === 'done'"
                  @click="onConfirmDeletion(data)"
                />
              </template>
            </Column>
          </template>
          <template #footer>
            <table class="flex justify-content-end text-grey-200">
              <tr>
                <td width="100%">
                  <table class="justify-content-end text-700">
                    <tr>
                      <td>SUB TOTAL</td>
                      <td style="width: 150px" class="text-right">
                        {{ formatCurrency(totalPO.bruto || 0) }}
                      </td>
                    </tr>
                    <tr>
                      <td>DISC REGULER</td>
                      <td class="text-right">
                        {{ formatCurrency(totalPO.disc_reg || 0) }}
                      </td>
                    </tr>
                    <tr>
                      <td>DISC EXTRA</td>
                      <td class="text-right">
                        {{ formatCurrency(totalPO.disc_ext || 0) }}
                      </td>
                    </tr>
                    <tr>
                      <td>PPN</td>
                      <td class="text-right">
                        {{ formatCurrency(totalPO.ppn || 0) }}
                      </td>
                    </tr>
                    <tr>
                      <td>GRAND TOTAL</td>
                      <td class="text-right">
                        {{ formatCurrency(totalPO.total || 0) }}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </template>
        </fx-table>
      </div>
    </div>
    <!-- </form> -->
    <Dialog
      :header="
        editMode === true
          ? 'Edit Item Order Pembelian'
          : 'Tambah Item Order Pembelian'
      "
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '40vw' }"
      :modal="true"
      :maximizable="true"
      @hide="onCloseSaveData"
    >
      <form-purchase-order
        :item="formDetail"
        :tax="form.tax"
        :loading="isLoadingSave"
        :list-satuan="list_satuan"
        :purchase-order="PurchaseOrder"
        :editMode="editMode"
        :isDppMinus="isDppMinus"
        :supp-Id="form.supp_id"
        :isFormSuccess="isFormSuccess"
        @save="onSaveData"
        @changeFormSuccess="onChangeFormSuccess"
        @close="onCloseSaveData"
      />
    </Dialog>
    <Dialog
      header="Hapus Order Pembelian"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Item Order Pembelian <strong>{{ formDetail.item_name }}</strong> akan
          dihapus. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteItemPurchaseOrder"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import SettingService from '@/services/SettingService'
import ItemSupplierService from '@/services/ItemSupplierService'
import SatuanService from '@/services/SatuanService'
import TermService from '@/services/TermService'
import PurchaseOrderService from '@/services/PurchaseOrderService'
import SupplierService from '@/services/SupplierService'
import FormPurchaseOrder from '@/components/pembelian/FormPurchaseOrder'
import FxTable from '@/components/FxTable'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import { FilterMatchMode } from 'primevue/api'
import { formatCurrency, ceiling } from '@/helpers/index'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import dayjs from 'dayjs'
export default {
  setup: () => ({ v$: useVuelidate() }),
  name: 'PurchaseOrder',
  components: {
    FormPurchaseOrder,
    FxTable,
    Hotkey,
  },
  data() {
    return {
      id: parseInt(this.$route.params.id),
      selectedSupplier: [],
      submitted: false,
      settingService: null,
      itemSupplierService: null,
      satuanService: null,
      termService: null,
      supplierService: null,
      purchaseOrderService: null,
      dialog: false,
      dialogHapus: false,
      isSetting: false,
      isLoading: false,
      isLoadingSave: false,
      editMode: false,
      isDppMinus: false,
      isFormSuccess: false,
      old_status_value: 'draft',
      items: [],
      list_supplier: [],
      listSupplier: [],
      list_term: [],
      list_satuan: [],
      list_items: [],
      list_itemsuppliers: [],
      list_global_setting: [],
      disc_reg: null,
      form: {
        pr_date: new Date(),
        pr_no: '',
        rcv_at: '',
        inv_no: '',
        supp_id: 0,
        term_id: 1,
        payment: 'tunai',
        tax: 0,
        whouse: 0,
        due_at: new Date(),
        status: 'draft',
        details: [],
      },
      formDetail: {
        item_id: '',
        unit_b: '',
        unit_s: '',
        cost_per: '',
        ubox: '',
        qty_b: 0,
        qty_s: 0,
        cost: 0,
        tax: 0,
        disc_reg: 0,
        disc_ext: 0,
      },
      isCheck: false,
      listDeleted: [],
      PurchaseOrder: [],
      containModesOption: [
        { label: 'Contains', value: FilterMatchMode.CONTAINS },
      ],
      equalModesOption: [{ label: 'Equals', value: FilterMatchMode.EQUALS }],
    }
  },
  computed: {
    list_status() {
      const list = [
        { value: 'draft', label: 'Draft', level: 0 },
        { value: 'done', label: 'Done', level: 1 },
      ]
      let old_status_level = 0
      if (this.old_status_value) {
        const status = list.find((e) => e.value === this.old_status_value)
        if (status) old_status_level = status.level
      }
      return list.map((status) => ({
        ...status,
        disabled: old_status_level > status.level,
      }))
    },
    totalPO() {
      // Calculate purchase.disc_reg
      let discRegTotal = this.form.details.reduce((total, detail) => {
        total += detail.disc_regv
        return total
      }, 0)

      let discExtTotal = this.form.details.reduce((total, detail) => {
        total += detail.disc_extv
        return total
      }, 0)

      // jumlah bruto
      let bruto = this.form.details.reduce((total, detail) => {
        total += detail.bruto
        return total
      }, 0)

      // Calculate total_dpp
      let dpp = bruto - discRegTotal - discExtTotal

      // Calculate total_ppn
      // let total_ppn = this.form.details.reduce((total, detail) => {
      //   total += Math.floor(detail.dpp * (detail.tax / 100))
      //   return total
      // }, 0)

      let total_ppn = Math.floor(dpp * (this.form.tax / 100))

      const total = {
        bruto: bruto,
        dpp: dpp,
        disc_reg: discRegTotal,
        disc_ext: discExtTotal,
        ppn: total_ppn,
        total: dpp + total_ppn,
      }
      return total
    },
  },
  created() {
    this.purchaseOrderService = new PurchaseOrderService()
    this.termService = new TermService()
    this.supplierService = new SupplierService()
    this.satuanService = new SatuanService()
    this.itemSupplierService = new ItemSupplierService()
    this.settingService = new SettingService()

    const purchaseOrderData = JSON.parse(localStorage.getItem('purchaseOrder'))
    if (purchaseOrderData && !this.id > 0) {
      this.editMode = false
      this.selectedSupplier = purchaseOrderData.supplier
      this.form.supp_id = purchaseOrderData.supp_id
      this.form.payment = purchaseOrderData.payment
      this.form.term_id = purchaseOrderData.term_id
      const details = purchaseOrderData.details
      details.forEach((item) => {
        this.onSaveData(item)
      })
    }
  },
  async mounted() {
    this.$refs.supplier.$el.querySelector('input').focus()
    if (this.id > 0) {
      this.isSetting = true
      this.loadData()
      this.isSetting = false
    }
    this.settingService
      .get()
      .then((res) => {
        this.list_global_setting = res.data

        const taxSetting = this.list_global_setting.find(
          (setting) => setting.key === 'tax'
        )
        if (taxSetting && parseFloat(taxSetting.value) > 0) {
          this.isCheck = true
          this.form.tax = parseFloat(taxSetting.value)
        }

        if (this.id == 0) {
          this.OnCheckClick()
        }
      })
      .catch((err) => {
        errorHandler(err, 'Data Setting', this)
      })
      .finally(() => (this.isLoading = false))

    this.supplierService
      .getActive()
      .then((res) => {
        this.list_supplier = res.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Supplier', this)
      })
      .finally(() => (this.isLoading = false))

    this.termService
      .get()
      .then((res) => {
        this.list_term = res.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Termin', this)
      })

    this.satuanService
      .get()
      .then((res) => {
        this.list_satuan = res.data.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Satuan', this)
      })
  },
  methods: {
    selectAllText(event) {
      if (event) {
        this.$refs[event].$el.querySelector('input').select()
      }
    },
    onCloseSaveData() {
      this.dialog = false
      // this.$refs.due_at.$el.querySelector('input').focus()
    },
    searchItemSuppliers(event) {
      const search = event.query
      this.supplierService
        .getListActive(search)
        .then((res) => {
          this.listSupplier = res.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Supplier', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onSelectSupplier() {
      if (this.selectedSupplier) {
        this.form.supp_id = this.selectedSupplier.id
        this.form.term_id = this.selectedSupplier.term_id
        this.form.payment = this.selectedSupplier.payment
      }
    },
    setDateJthTempo() {
      const idx = this.list_term.findIndex(
        (item) => item.id === this.form.term_id
      )
      if (idx != -1) {
        const pr_date = dayjs(this.form.pr_date)
        const due_at = pr_date.add(this.list_term[idx].term, 'day')
        this.form.due_at = new Date(due_at)
      }
    },
    totalDetail(detail) {
      let qty_b = detail['qty_b']
      let qty_s = detail['qty_s']

      // hitung total qty
      let qty = 0
      if (qty_b > 0) {
        qty = detail['ubox'] * qty_b
      }
      qty += qty_s

      // hitung total bruto dan harga per unit
      let bruto = 0
      let cost_s = 0
      if (detail['cost_per'] === detail['unit_b']) {
        bruto = Math.round(detail['cost'] / detail['ubox']) * qty
        cost_s = Math.round(detail['cost'] / detail['ubox'])
      } else {
        bruto = Math.round(detail['cost']) * qty
        cost_s = Math.round(detail['cost'])
      }

      //  round bruto and cost per unit
      // bruto = Number(bruto.toFixed(2))
      // cost_s = Number(cost_s.toFixed(2))

      //  disc reg
      let disc_regv = 0
      let disc_reg = detail.disc_reg
      if (disc_reg <= 1.0 || disc_reg <= 100.0) {
        // disc_regv = Number((cost_s * (disc_reg / 100)).toFixed(2))
        disc_regv = Math.round(cost_s * (disc_reg / 100))
      } else if (disc_reg > 100.0) {
        // disc_regv = Number(disc_reg.toFixed(2))
        disc_regv = Math.round(disc_reg.toFixed(2))
      }

      cost_s -= disc_regv
      if (cost_s < 0) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Order Pembelian',
          detail: 'Total diskon reguler tidak dapat melebihi harga satuan.',
          life: 3000,
        })
        return false
      }

      // total diskon reg
      disc_regv *= qty

      //  disc ext
      let disc_extv = 0
      let disc_ext = detail.disc_ext
      if (disc_ext <= 1 || disc_ext <= 100.0) {
        // disc_extv = Number((cost_s * (disc_ext / 100)).toFixed(2))
        disc_extv = Math.round(cost_s * (disc_ext / 100))
      } else if (disc_ext > 100.0) {
        // disc_extv = Number(disc_ext.toFixed(2))
        disc_extv = Math.round(disc_ext.toFixed(2))
      }

      cost_s -= disc_extv
      if (cost_s < 0) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Order Pembelian',
          detail: 'Total diskon extra tidak dapat melebihi harga satuan.',
          life: 3000,
        })
        return false
      }

      // total diskon ext
      disc_extv *= qty

      // total dpp
      let dpp = cost_s * qty
      let total = dpp

      // price
      let price = Number((cost_s / (1 - detail['item_mrg'] / 100)).toFixed(2))
      let ppn = Math.floor(price * (this.form.tax / 100))
      price = ceiling(price + ppn)

      return {
        qty: qty,
        bruto: bruto,
        dpp: dpp,
        // ppn: ppn,
        total: total,
        price: price,
        cost_s: cost_s,
        disc_regv: disc_regv,
        disc_extv: disc_extv,
      }
    },
    changeDiscReg() {
      if (this.isSetting == false) {
        this.form.details.forEach((item) => {
          item.disc_reg = this.disc_reg
          const totalDetail = this.totalDetail(item)
          Object.assign(item, { ...item, ...totalDetail })
        })
      }
    },
    OnCheckClick() {
      const idx = this.list_global_setting.findIndex(
        (item) => item.key === 'tax'
      )
      const ppn = idx != -1 ? this.list_global_setting[idx].value : 10
      this.form.tax = this.form.tax == 0 ? ppn : 0
      this.isCheck = this.form.tax == 0 ? false : true

      this.form.details.forEach((item) => {
        item.tax = this.form.tax
      })
    },
    onConfirmSave(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      const deleted = this.id ? { deleted: this.listDeleted } : {}

      const pr_date = this.form.pr_date
        ? { pr_date: dayjs(this.form.pr_date).format('YYYY-MM-DD') }
        : null
      const rcv_at = this.form.rcv_at
        ? { rcv_at: dayjs(this.form.rcv_at).format('YYYY-MM-DD') }
        : null
      const due_at = this.form.due_at
        ? { due_at: dayjs(this.form.due_at).format('YYYY-MM-DD') }
        : null
      const form = {
        ...pr_date,
        ...rcv_at,
        ...due_at,
        status: this.form.status,
        pr_no: this.form.pr_no,
        inv_no: this.form.inv_no,
        supp_id: this.form.supp_id,
        term_id: this.form.term_id,
        payment: this.form.payment,
        tax: this.form.tax,
        whouse: this.form.whouse || 0,
        details: this.form.details.map((item) => {
          const id = item.id ? { id: item.id } : this.id ? { id: 0 } : {}
          let disc_reg = item.disc_reg ? item.disc_reg : 0
          let disc_ext = item.disc_ext ? item.disc_ext : 0
          return {
            ...id,
            item_id: item.item_id,
            unit_b: item.unit_b,
            unit_s: item.unit_s,
            cost_per: item.cost_per,
            ubox: item.ubox,
            qty_b: item.qty_b,
            qty_s: item.qty_s,
            cost: item.cost,
            disc_reg: disc_reg,
            disc_ext: disc_ext,
            tax: item.tax,
          }
        }),
        ...deleted,
      }

      if (!this.id) {
        this.isLoadingSave = true
        this.purchaseOrderService
          .add(form)
          .then((res) => {
            if (res.data.status === 1) {
              this.$toast.add({
                severity: 'success',
                summary: 'Order Pembelian',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              if (localStorage.getItem('purchaseOrder') !== null) {
                localStorage.removeItem('purchaseOrder')
              }

              this.$router.push({ name: 'PurchaseOrder' })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Order Pembelian', this)
          })
          .finally(() => {
            this.deleted = []
            this.isLoadingSave = false
          })
      } else {
        this.isLoadingSave = true
        this.purchaseOrderService
          .update(this.id, form)
          .then((res) => {
            if (res.data.status === 1) {
              this.loadData()
              this.$toast.add({
                severity: 'success',
                summary: 'Order Pembelian',
                detail: 'Data berhasil diupdate.',
                life: 3000,
              })
              if (this.form.status === 'done') {
                this.$router.push({ name: 'PurchaseOrder' })
              }
            }
          })
          .catch((err) => {
            errorHandler(err, 'Order Pembelian', this)
          })
          .finally(() => {
            this.isLoadingSave = false
          })
      }
    },
    formatCurrency(val) {
      return formatCurrency(val, 0, 0)
    },

    loadData() {
      this.isLoading = true
      this.purchaseOrderService
        .getById(this.id)
        .then((res) => {
          this.form = res.data.data
          this.old_status_value = this.form.status
          this.form.due_at = this.form.due_at ? new Date(this.form.due_at) : ''
          this.form.pr_date = this.form.pr_date
            ? new Date(this.form.pr_date)
            : ''
          this.form.rcv_at = this.form.rcv_at ? new Date(this.form.rcv_at) : ''
          this.isCheck = this.form.tax > 0
          this.selectedSupplier = this.form.supplier
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data Order Pembelian', this)
        })
        .finally(() => (this.isLoading = false))
    },

    onEditData(data) {
      this.editMode = true
      this.formDetail = Object.assign({}, data)
      this.dialog = true
    },
    supplierInputEnter() {
      if (this.selectedSupplier.id) {
        this.onAddData()
      }
    },
    dueDateEnter() {
      this.$refs.supplier.$el.querySelector('input').focus()
    },
    onAddData() {
      if (!this.form.supp_id) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Order Pembelian',
          detail: 'Supplier harus diisi terlebih dahulu.',
          life: 3000,
        })
        return
      }

      this.editMode = false
      this.formDetail = {
        item_id: '',
        unit_b: '',
        unit_s: '',
        cost_per: '',
        ubox: '',
        qty_b: 0,
        qty_s: 0,
        cost: 0,
        disc_reg: 0,
        disc_ext: 0,
        tax: this.form.tax,
      }
      this.dialog = true
    },
    async onSaveData(data) {
      const detailItem = Object.assign({}, data)

      const idx = this.form.details.findIndex(
        (item) => item.item_id === detailItem.item_id
      )

      const totalDetail = await this.totalDetail(detailItem)

      if (this.editMode) {
        if (!totalDetail) {
          return
        }
        if (totalDetail.qty < detailItem.min_order) {
          this.$toast.add({
            severity: 'warn',
            summary: 'Order Pembelian',
            detail:
              'Item ' +
              detailItem.item_name +
              ' min order : ' +
              detailItem.min_order,
            life: 3000,
          })
          this.isDppMinus = true
          this.isFormSuccess = false
          return
        } else {
          Object.assign(this.form.details[idx], {
            ...detailItem,
            ...totalDetail,
          })
          this.dialog = this.editMode ? false : this.dialog
          this.isDppMinus = false
          this.isFormSuccess = true
        }
      } else {
        const payload = { ...detailItem, ...totalDetail }
        if (idx === -1) {
          if (!totalDetail) {
            return
          }
          if (detailItem.qty_b === 0 && detailItem.qty_s === 0) {
            this.$toast.add({
              severity: 'warn',
              summary: 'Order Pembelian',
              detail: 'QTY harus diisi',
              life: 3000,
            })
            this.isDppMinus = false
            this.isFormSuccess = false
            return
          }
          if (totalDetail.qty < detailItem.min_order) {
            this.$toast.add({
              severity: 'warn',
              summary: 'Order Pembelian',
              detail:
                'Item ' +
                payload.item_name +
                ' min order : ' +
                detailItem.min_order,
              life: 3000,
            })
            this.isDppMinus = true
            this.isFormSuccess = false
            return
          } else {
            this.form.details.push(payload)
            this.$toast.add({
              severity: 'success',
              summary: 'Order Pembelian',
              detail: 'Item ' + payload.item_name + ' berhasil ditambahkan.',
              life: 3000,
            })
            this.dialog = this.editMode ? false : this.dialog
            this.isDppMinus = false
            this.isFormSuccess = true
            return
          }
        } else {
          this.$toast.add({
            severity: 'error',
            summary: 'Order Pembelian',
            detail: 'Item ' + payload.item_name + ' sudah ada',
            // this.form.pr_no,
            life: 3000,
          })
          this.isFormSuccess = true
        }
      }
    },
    async onChangeFormSuccess() {
      this.isFormSuccess = false
    },
    onConfirmDeletion(data) {
      this.formDetail = data
      this.dialogHapus = true
    },
    onDeleteItemPurchaseOrder() {
      const index = this.form.details.findIndex(
        (el) => el.item_id === this.formDetail.item_id
      )
      if (index !== -1) {
        if (this.id && this.form.details[index].id) {
          this.listDeleted.push(this.form.details[index].id)
        }
        this.form.details.splice(index, 1)
        this.$toast.add({
          severity: 'success',
          summary: 'Data Item Order Pembelian',
          detail: 'Data berhasil dihapus.',
          life: 3000,
        })
      }
      this.dialogHapus = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAddData()
      }
    },
    /* onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col))
    }, */
  },
  validations() {
    return {
      form: {
        pr_date: {
          required: helpers.withMessage('Tgl Order harus diisi.', required),
        },
        supp_id: {
          required: helpers.withMessage('Supplier harus diisi.', required),
        },
        term_id: {
          required: helpers.withMessage('Termin harus diisi.', required),
        },
        payment: {
          required: helpers.withMessage('payment harus diisi.', required),
        },
      },
    }
  },
}
</script>
